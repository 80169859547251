// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "z_fc";
export var multiVendorBusinessesSection = "z_d5";
export var multiVendorClinetsSection = "z_fj";
export var multiVendorDevExpertiseSection = "z_ff";
export var multiVendorDiscoverWorksSection = "z_fb";
export var multiVendorFeaturesSection = "z_d4";
export var multiVendorIdeaToLifeSection = "z_d9";
export var multiVendorIndustriesSection = "z_d6";
export var multiVendorOurAchievementsSection = "z_fh";
export var multiVendorProcessSection = "z_d7";
export var multiVendorProjLogoSection = "z_fd";
export var multiVendorServicesSection = "z_d3";
export var multiVendorSuccessStoriesSection = "z_fg";
export var multiVendorTechStackSection = "z_d8";